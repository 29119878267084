import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import Link from 'next/link';
import classNames from 'classnames';
import { useTranslation } from 'next-i18next';
import _ from 'lodash';
import Menu from 'Components/Header/Menu';
import MenuMobile from 'Components/Header/MenuMobile';
import Image from 'Components/Image';
import Logo from 'Assets/img/logo.svg';
import s from './Header.module.scss';

const Header = ({ primary, secondary, mobile, currentId, memberLogin, searchUrl }) => {
    const { t } = useTranslation();

    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
    const [memberMenuOpen, setMemberMenuOpen] = useState(false);

    useEffect(() => {
        const hasClass = document.body.classList.contains('js-state-menumobile-open');
        if(mobileMenuOpen && !hasClass) {
            document.body.classList.add('js-state-menumobile-open');
        } else if(!mobileMenuOpen && hasClass) {
            document.body.classList.remove('js-state-menumobile-open');
        }
    }, [mobileMenuOpen]);

    const toggleText = mobileMenuOpen ? t('header.openMenu') : t('header.closeMenu');

    const search = {
        title: t('header.search'),
        url: searchUrl,
        modifier: 'Search',
    };
    const menuSecondaryItems = _.union([search], [...secondary.items]);
    const menuSecondary = {...secondary, items: menuSecondaryItems};

    const menuMemberItems = [...memberLogin.items].map(({type, url}) => {
        return {url: url, title: t(`header.memberLinks.${type}`)};
    });
    const member = {...memberLogin, items: menuMemberItems};

    const classes = classNames(
        [s['Header']],
        {[s['Header--MobileMenuOpen']]: mobileMenuOpen},
        {[s['Header--MemberMenuOpen']]: memberMenuOpen},
    );

    return (
        <header className={classes}>
            <div className={s['Header__Main']}>
                <div className={s['Header__LogoWrap']}>
                    <div className={s['Header__Logo']}>
                        <Link className={s['Header__LogoLink']} href="/">
                            <Image
                                src={Logo.src}
                                alt={t('header.toStartpage')}
                                width={Logo.width}
                                height={Logo.height}
                                lazyLoad={false}
                            />
                        </Link>
                    </div>
                </div>
                <div className={s['Header__ServiceWrap']}>
                    <div className={s['Header__Service']}>
                        <Menu
                            {...menuSecondary}
                            label={t('header.serviceLabel')}
                            modifier="Service"
                        />
                    </div>
                </div>
                <div className={s['Header__PrimaryWrap']}>
                    <div className={s['Header__Primary']}>
                        <Menu
                            {...primary}
                            label={t('header.primaryLabel')}
                            modifier="Primary"
                        />
                    </div>

                    <Member
                        {...member}
                        setOpen={setMemberMenuOpen}
                    />

                    <button
                        className={s['Header__Toggle']} 
                        onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
                    >
                        <span className="sr-only">{toggleText}</span>
                    </button>
                </div>
            </div>
            <div className={s['Header__MenuMobile']}>
                <MenuMobile
                    isOpen={mobileMenuOpen}
                    menu={mobile}
                    currentId={currentId}
                    secondary={secondary}
                    search={search}
                    memberLogin={member}
                />
            </div>
        </header>
    );
};

Header.propTypes = {
    primary: PropTypes.object,
    secondary: PropTypes.object,
    mobile: PropTypes.object,
    currentId: PropTypes.number,
    memberLogin: PropTypes.object,
    searchUrl: PropTypes.string,
};

Header.defaultProps = {
    primary: {},
    secondary: {
        items: [],
    },
    mobile: {},
    currentId: 0,
    memberLogin: {
        items: [],
    },
    searchUrl: '',
};

const Member = ({link, items, setOpen}) => {
    const { t } = useTranslation();

    const classes = classNames(
        [s['Header__Member']],
        {[s['Header__Member--Active']]: link.isActive},
    );
    return (
        <div
            className={classes}
            onMouseOver={() => setOpen(true)}
            onMouseLeave={() => setOpen(false)}
        >
            <a
                className={s['Header__MemberLink']}
                href={link.url}
            >
                <span className="sr-only">{t(`header.memberLinks.${link.type}`)}</span>
            </a>
            {items.length > 0 &&
                <ul className={s['Header__Sub']}>
                    {items.map(({url, title}, index) => (
                        <li className={s['Header__SubItem']} key={index}>
                            <a href={url} className={s['Header__SubLink']}>
                                <span>{title}</span>
                            </a>
                        </li>
                    ))}
                </ul>
            }
        </div>
    );
};

Member.propTypes = {
    link: PropTypes.object,
    items: PropTypes.array,
    setOpen: PropTypes.func,
};

Member.defaultProps = {
    link: {},
    items: [],
    setOpen: null,
};

export default Header;