import SanitizeHTML from 'sanitize-html-react';

/*
    Transform target can be used to make sure all links open in new window,
    e.g. in views where links shouldn't break user progress
*/

// For custom frontend fields with less formatting
export const sanitizeBasicTags = (text, transformTarget=false) => {
    return SanitizeHTML(text, {
        allowedTags: [
            'br', 'b', 'i', 'em', 'strong', 'a', 'abbr', 'span', 'sup', 'sub',
        ],
        allowedAttributes: {a: ['href', 'target']},
        allowedSchemes: ['http', 'https', 'mailto', 'tel'],
        transformTags: {
            a: (tag, atts) => {
                const target = atts.href && !/^(mailto:|tel:)/i.test(atts.href);
                const newAtts = transformTarget && target ?
                    Object.assign(atts, {target: '_blank'}) : atts;
                return {tagName: tag, attribs: newAtts};
            },
        },
    });
};

// For acf fields with full editor
export const sanitizeFullHTML = (text, transformTarget=false) => {
    return SanitizeHTML(text, {
        allowedTags: [
            'p', 'br', 'b', 'i', 'em', 'strong', 'abbr', 'a', 'span', 'del',
            'ul', 'ol', 'li', 'blockquote', 'sup', 'sub', 'h2', 'h3', 'h4', 'h5', 'h6'
        ],
        allowedAttributes: {a: ['href', 'target', 'rel'], p: ['style'], span: ['style']},
        allowedSchemes: ['http', 'https', 'mailto', 'tel'],
        transformTags: {
            a: (tag, atts) => {
                const target = atts.href && !/^(mailto:|tel:)/i.test(atts.href);
                const newAtts = transformTarget && target ? 
                    Object.assign(atts, {target: '_blank'}) : atts;
                return {tagName: tag, attribs: newAtts};
            },
        },
    });
};

// For acf fields with basic editor
export const sanitizeBasicHTML = (text, transformTarget=false) => {
    return SanitizeHTML(text, {
        allowedTags: [
            'p', 'br', 'b', 'i', 'em', 'strong', 'abbr', 'a', 'span', 'del',
            'ul', 'ol', 'li', 'blockquote', 'sup', 'sub',
        ],
        allowedAttributes: {a: ['href', 'target', 'rel'], p: ['style'], span: ['style']},
        allowedSchemes: ['http', 'https', 'mailto', 'tel'],
        transformTags: {
            a: (tag, atts) => {
                const target = atts.href && !/^(mailto:|tel:)/i.test(atts.href);
                const newAtts = transformTarget && target ? 
                    Object.assign(atts, {target: '_blank'}) : atts;
                return {tagName: tag, attribs: newAtts};
            },
        },
    });
};

// For wysiwyg with meda uploads
export const sanitizeHTMLWithUploads = (text, transformTarget=false) => {
    return SanitizeHTML(text, {
        allowedTags: [
            'p', 'br', 'b', 'i', 'em', 'strong', 'abbr', 'a', 'span', 'del',
            'ul', 'ol', 'li', 'blockquote', 'sup', 'sub', 'figure', 'figcaption',
            'img',
        ],
        allowedAttributes: {
            a: ['href', 'target', 'rel'],
            p: ['style'],
            span: ['style'],
            figure: ['class'],
            img: [
                'src', 'alt', 'width', 'height', 'class', 'loading', 'title',
                'style', 'srcset', 'sizes',
            ],
        },
        allowedSchemes: ['http', 'https', 'mailto', 'tel'],
        transformTags: {
            a: (tag, atts) => {
                const target = atts.href && !/^(mailto:|tel:)/i.test(atts.href);
                const newAtts = transformTarget && target ? 
                    Object.assign(atts, {target: '_blank'}) : atts;
                return {tagName: tag, attribs: newAtts};
            },
        },
    });
};

// For text field in acf with automatic formatting
export const sanitizeParagraphs = (text) => {
    return SanitizeHTML(text, {
        allowedTags: ['p', 'br'],
    });
};

// For fields that shouldn't contain any html tags
export const sanitizeText = (text) => {
    return SanitizeHTML(text, {
        allowedTags: [],
    });
};
