import React from 'react';
import PropTypes from 'prop-types';
import Head from 'next/head';
import Script from 'next/script';
import classNames from 'classnames';
import Footer from 'Components/Footer';
import Header from 'Components/Header';
import WPAdminBar from 'Components/WPAdminBar';
import s from './Base.module.scss';

const Base = (Container) => {
    const { displayName } = Container;

    const Wrapper = ({
        header = {},
        footer = {},
        wpAdminBar = {},
        containerName = '',
        seo = {},
        scripts = {},
        ...restProps
    }) => {
        const { footer: footerScripts } = scripts;

        const type = containerName ? containerName : displayName;

        const classes = classNames(
            [s['Base']],
            [s[`Base--${type}`]],
            // For global styling
            'Base',
            [`Base--${type}`],
        );

        return (
            <>
                <Seo {...seo} />

                <div className={classes}>
                    <Header {...header} />

                    <div className={s['Base__Document']} role="document">
                        <main className={s['Base__Main']}>
                            <Container {...restProps} />
                        </main>
                    </div>

                    <Footer {...footer} />
                </div>

                {!!wpAdminBar && <WPAdminBar {...wpAdminBar} />}

                {footerScripts && (footerScripts.length > 0) && footerScripts.map(({src, attributes, children}, index) => (
                    <Script
                        key={index}
                        {...(!!src && {src: src})}
                        {...attributes}
                        dangerouslySetInnerHTML={{__html: children}}
                        onError={() => {
                            console.log(`Error loading script ${attributes?.id}: ${src}${children}`);
                        }}
                    />
                ))}
            </>
        );
    };

    Wrapper.propTypes = {
        containerName: PropTypes.string,
        header: PropTypes.object,
        footer: PropTypes.object,
        seo: PropTypes.object,
        scripts: PropTypes.object,
        wpAdminBar: PropTypes.object,
    };

    return Wrapper;
};

Base.propTypes = {
    displayName: PropTypes.string,
};

const Seo = ({
    title = '',
    description = '',
    siteName = '',
    ogLocale = '',
    ogTitle = '',
    ogDescription = '',
    ogUrl = '',
    ogSiteName = '',
    ogImage = {},
    ogType = '',
    twitterSite = '',
    twitterTitle = '',
    twitterDescription = '',
    twitterUrl = '',
    twitterImage = '',
    twitterCard = 'summary',
    robots = '',
    canonical = '',
    articlePublisher = '',
    articleModifiedTime = '',
}) => {
    return (
        <Head>
            <title>{title}</title>

            {!!canonical && (
                <link rel="canonical" href={canonical} />
            )}

            <meta name="application-name" content={siteName} />

            {!!description && (
                <meta name="description" content={description} />
            )}
            {!!ogLocale && (
                <meta property="og:locale" content={ogLocale} />
            )}
            {!!ogTitle && (
                <meta property="og:title" content={ogTitle} />
            )}
            {!!ogDescription && (
                <meta
                    property="og:description"
                    content={ogDescription}
                />
            )}
            {!!ogUrl && <meta property="og:url" content={ogUrl} />}
            {!!ogSiteName && <meta property="og:site_name" content={ogSiteName} />}
            {!!ogImage && (
                <>
                    <meta property="og:image" content={ogImage?.src} />
                    <meta property="og:image:width" content={ogImage?.width} />
                    <meta property="og:image:height" content={ogImage?.height} />
                    <meta property="og:image:type" content={ogImage?.type} />
                </>
            )}
            {!!ogType && <meta property="og:type" content={ogType} />}
            {!!twitterSite && (
                <meta name="twitter:site" content={twitterSite} />
            )}
            {!!twitterTitle && (
                <meta property="twitter:title" content={twitterTitle} />
            )}
            {!!twitterDescription && (
                <meta
                    property="twitter:description"
                    content={twitterDescription}
                />
            )}
            {!!twitterUrl && (
                <meta property="twitter:url" content={twitterUrl} />
            )}
            {!!twitterImage && (
                <meta property="twitter:image" content={twitterImage} />
            )}
            {!!twitterCard && (
                <meta property="twitter:card" content={twitterCard} />
            )}
            {!!articlePublisher && (
                <meta property="article:publisher" content={articlePublisher} />
            )}
            {!!articleModifiedTime && (
                <meta property="article:modified_time" content={articleModifiedTime} />
            )}
            <meta name="robots" content={robots} />
        </Head>
    );
};

Seo.propTypes = {
    siteName: PropTypes.string,
    title: PropTypes.string,
    canonical: PropTypes.string,
    description: PropTypes.string,
    ogLocale: PropTypes.string,
    ogSiteName: PropTypes.string,
    ogTitle: PropTypes.string,
    ogDescription: PropTypes.string,
    ogUrl: PropTypes.string,
    ogImage: PropTypes.object,
    ogType: PropTypes.string,
    twitterSite: PropTypes.string,
    twitterTitle: PropTypes.string,
    twitterDescription: PropTypes.string,
    twitterUrl: PropTypes.string,
    twitterImage: PropTypes.string,
    twitterCard: PropTypes.string,
    articlePublisher: PropTypes.string,
    articleModifiedTime: PropTypes.string,
    robots: PropTypes.string,
};

export default Base;