import React, {useState} from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'next-i18next';
import classNames from 'classnames';
import _ from 'lodash';
import s from './SearchForm.module.scss';

const SearchForm = ({url, title, id, type}) => {
    const {t} = useTranslation();

    const [value, setValue] = useState('');
    const [isFocus, setFocus] = useState(false);

    const defaultPlaceholder = t([`searchForm.placeholder.${type}`, 'searchForm.placeholder.default']);
    const defaultLabel = t([`searchForm.label.${type}`, 'searchForm.label.default']);
    const submitText = t([`searchForm.submit.${type}`, 'searchForm.submit.default']);
    const placeholder = !_.isEmpty(title) ? title : defaultPlaceholder;
    const label = !_.isEmpty(title) ? title : defaultLabel;

    const classes = classNames(
        [s['SearchForm']],
        {[s['SearchForm--HasValue']]: !_.isEmpty(value)},
        {[s['SearchForm--Focus']]: isFocus},
        {[s[`SearchForm--${_.upperFirst(type)}`]]: type},
    );

    return (
        <form className={classes} action={url}>
            <div className={s['SearchForm__Field']}>
                <label className={s['SearchForm__Label']} htmlFor={id}>
                    <span className={s['SearchForm__LabelText']}>{label}</span>
                </label>
                <input
                    className={s['SearchForm__Input']}
                    type="text"
                    name="search"
                    id={id}
                    placeholder={placeholder}
                    onChange={(e) => setValue(e.target.value)}
                    onFocus={() => setFocus(true)}
                    onBlur={() => setFocus(false)}
                />
            </div>
            <button
                className={s['SearchForm__Submit']}
                type="submit"
            >{submitText}</button>
        </form>
    );
};

SearchForm.propTypes = {
    title: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    type: PropTypes.string,
};

SearchForm.defaultProps = {
    title: '',
    url: '',
    id: 'search-form',
    type: '',
};

export default SearchForm;
