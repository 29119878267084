import React, {useState} from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'next-i18next';
import classNames from 'classnames';
import _ from 'lodash';
import ArrowRight from 'Assets/svg/arrowRight.svg';
import { AnimateUpDown } from 'Components/Animate';
import s from './SubMenu.module.scss';

const SubMenu = ({isMobileMenu, currentId, items, level, label, modifier, basePadding}) => {
    if(_.isEmpty(items))
        return null;

    const classes = classNames(
        [s['SubMenu']],
        {[s[`SubMenu--${modifier}`]] : modifier},
    );

    return (
        <nav className={classes} aria-label={label}>
            <List
                items={items}
                currentId={currentId}
                level={level}
                basePadding={basePadding}
                isMobileMenu={isMobileMenu}
            />
        </nav>
    );
};

SubMenu.propTypes = {
    isMobileMenu: PropTypes.bool,
    currentId: PropTypes.number,
    items: PropTypes.array,
    level: PropTypes.number,
    label: PropTypes.string,
    modifier: PropTypes.string,
    basePadding: PropTypes.number,
};

SubMenu.defaultProps = {
    isMobileMenu: false,
    currentId: 0,
    items: [],
    level: 0,
    label: null,
    modifier: '',
    basePadding: 24,
};

const List = ({isMobileMenu, currentId, items, level, basePadding}) => {
    const classes = classNames(
        [s['SubMenu__List']],
        [s[`SubMenu__List--Level-${level}`]],
    );
    return (
        <ul className={classes}>
            {items.map((item, index) => (
                <Item
                    key={index}
                    {...item}
                    currentId={currentId}
                    level={level}
                    basePadding={basePadding}
                    isMobileMenu={isMobileMenu}
                />
            ))}
        </ul>
    );
};

List.propTypes = {
    isMobileMenu: PropTypes.bool,
    currentId: PropTypes.number,
    items: PropTypes.array,
    level: PropTypes.number,
    basePadding: PropTypes.number,
};

List.defaultProps = {
    isMobileMenu: false,
    currentId: 0,
    items: [],
    level: 0,
    basePadding: 24,
};

const Item = ({isMobileMenu, currentId, id, title, url, children, level, basePadding, modifier}) => {
    const {t} = useTranslation();

    const flat = (item) => {
        return item.children ? [item.id, ...item.children.map(flat)] : item.id;
    };
    const allSubIds = _.flattenDeep([id, ...children.map(flat)]);
    const defaultOpen = allSubIds.includes(currentId) || currentId === id;

    const [isOpen, setIsOpen] = useState(defaultOpen);

    const isRoot = level === 0;
    const hasChildren = !_.isEmpty(children);
    const extra = (level - 1) * 16;
    const style = {paddingLeft: `${Math.max(basePadding, basePadding + extra)}px`};

    const classes = classNames(
        [s['SubMenu__Item']],
        {[s['SubMenu__Item--Root']]: isRoot},
        {[s['SubMenu__Item--Open']]: isOpen},
        {[s['SubMenu__Item--Current']]: currentId === id},
        {[s['SubMenu__Item--HasChildren']]: hasChildren},
        {[s[`SubMenu__Item--${modifier}`]]: modifier},
    );
    return (
        <li className={classes}>
            <a className={s['SubMenu__Link']} href={url} style={style}>
                <span className={s['SubMenu__Text']}>{title}</span>
            </a>
            
            {!isRoot && hasChildren &&
                <button
                    className={s['SubMenu__Toggle']}
                    onClick={() => setIsOpen(!isOpen)}
                >
                    <span className="sr-only">{t('subMenu.toggle')}</span>

                    <ArrowRight />
                </button>
            }

            {/* For not rendering all elements in dom when mobile menu is used */}
            {isMobileMenu && hasChildren &&
                <AnimateUpDown
                    isVisible={isRoot || isOpen}
                >
                    <List
                        items={children}
                        currentId={currentId}
                        level={level+1}
                        basePadding={basePadding}
                        isMobileMenu={isMobileMenu}
                    />
                </AnimateUpDown>
            }

            {!isMobileMenu && hasChildren &&
                <AnimateUpDown
                    isVisible={isRoot || isOpen}
                >
                    <List
                        items={children}
                        currentId={currentId}
                        level={level+1}
                        basePadding={basePadding}
                        isMobileMenu={isMobileMenu}
                    />
                </AnimateUpDown>
            }
        </li>
    );
};

Item.propTypes = {
    isMobileMenu: PropTypes.bool,
    currentId: PropTypes.number,
    id: PropTypes.number,
    title: PropTypes.string,
    url: PropTypes.string,
    children: PropTypes.array,
    level: PropTypes.number,
    basePadding: PropTypes.number,
    modifier: PropTypes.string,
};

Item.defaultProps = {
    isMobileMenu: false,
    currentId: 0,
    id: 0,
    title: '',
    url: '',
    children: [],
    level: 0,
    basePadding: 24,
    modifier: '',
};

export default SubMenu;
