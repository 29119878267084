import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'next-i18next';
import _ from 'lodash';
import Facebook from 'Assets/svg/facebook.svg';
import Linkedin from 'Assets/svg/linkedin.svg';
import Twitter from 'Assets/svg/twitter.svg';
import Instagram from 'Assets/svg/instagram.svg';
import s from './Follow.module.scss';

const Follow = ({title, items}) => (
    <div className={s['Follow']}>
        <h2 className={s['Follow__Label']}>{title}</h2>
        <ul className={s['Follow__List']}>
            {items.map((item, index) => 
                <Item 
                    {...item}
                    key={index}
                />
            )}
        </ul>
    </div>
);

Follow.propTypes = {
    title: PropTypes.string,
    items: PropTypes.array,
};

Follow.defaultProps = {
    title: '',
    items: [],
};

const Item = ({url, title}) => {
    const {t} = useTranslation();
    const icons = {Facebook, Linkedin, Twitter, Instagram};
    const Icon = icons[_.upperFirst(_.toLower(title))];
    const text = t('footer.followUs', {title: title});
    return (
        <li className={s['Follow__Item']}>
            <a className={s['Follow__Link']} href={url}>
                <Icon />
                <span className="sr-only">{text}</span>
            </a>
        </li>
    );
};

Item.propTypes = {
    url: PropTypes.string,
    title: PropTypes.string,
};

Item.defaultProps = {
    url: '',
    title: '',
};

export default Follow;
