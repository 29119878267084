import React, {useState} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useTranslation } from 'next-i18next';
import _ from 'lodash';
import ArrowIcon from 'Assets/svg/arrowRight.svg';
import SubMenu from 'Components/SubMenu';
import s from './MenuMobile.module.scss';

const MenuMobile = ({ menu, currentId, secondary, search, memberLogin }) => {
    const { t } = useTranslation();

    const menuItems = _.get(menu, 'items', []);

    const menuPrimaryItems = _.union([search], [...menuItems]);
    const menuPrimary = {...menu, items: menuPrimaryItems};
    
    return (
        <div className={s['MenuMobile']}>
            <div className={s['MenuMobile__Wrap']}>
                <SubMenu
                    {...menuPrimary}
                    currentId={currentId}
                    label={t('header.primaryLabel')}
                    modifier="MobileMenu"
                    level={1}
                    basePadding={15}
                    isMobileMenu={true}
                />
                <Navigation
                    {...secondary}
                    label={t('header.serviceLabel')}
                    modifier="Service"
                />
                <Navigation
                    {...memberLogin}
                    label={t('header.memberLabel')}
                    modifier="Service"
                />
            </div>
        </div>
    );
};

MenuMobile.propTypes = {
    menu: PropTypes.object,
    currentId: PropTypes.number,
    secondary: PropTypes.object,
    search: PropTypes.object,
    memberLogin: PropTypes.object,
};

MenuMobile.defaultProps = {
    menu: {},
    currentId: 0,
    secondary: {},
    search: {},
    memberLogin: {},
};

const Navigation = ({ label, items, modifier }) => {
    const classes = classNames(
        [s['MenuMobile__Navigation']],
        {[s[`MenuMobile__Navigation--${modifier}`]]: modifier},
    );

    return (
        <nav
            className={classes}
            aria-label={label}
        >
            <ul className={s['MenuMobile__List']}>
                {items.map((item, index) => 
                    <Item {...item} key={index} />
                )}
            </ul>
        </nav>
    );
};

Navigation.propTypes = {
    label: PropTypes.string.isRequired,
    items: PropTypes.array.isRequired,
    modifier: PropTypes.string,
};

Navigation.defaultProps = {
    label: '',
    items: [],
    modifier: '',
};

const Item = ({ 
    title, 
    url, 
    children,
    modifier,
}) => {
    const [isVisable, setIsVisable] = useState(false);

    const navItemClass = classNames(
        [s['MenuMobile__Item']],
        {[s['MenuMobile__Item--Has-Children']]: children.length},
        {[s['MenuMobile__Item--Visable']]: children.length && isVisable},
        {[s[`MenuMobile__Item--${modifier}`]]: modifier},
    );
    
    return (
        <li className={navItemClass} >
            <a className={s['MenuMobile__Link']} href={url}>{title}</a>
            <span 
                className={s['MenuMobile__Toggle']} 
                onClick={() => setIsVisable(!isVisable)}
            ><ArrowIcon /></span>
            {children &&
                <ul className={s['MenuMobile__Sub']}>
                    {children.map(({ url, title }, index) => 
                        <li className={s['MenuMobile__SubItem']} key={index} >
                            <a href={url} className={s['MenuMobile__SubLink']}>{title}</a>
                        </li>
                    )}
                </ul>
            }
        </li>
    );
};

Item.propTypes = {
    title: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    children: PropTypes.array,
    modifier: PropTypes.string,
};

Item.defaultProps = {
    title: '',
    url: '',
    children: [],
    modifier: '',
};

export default MenuMobile;
