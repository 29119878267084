import React from 'react';
import PropTypes from 'prop-types';
import Link from 'next/link';
import { useTranslation } from 'next-i18next';
import classNames from 'classnames';
import { sanitizeParagraphs } from 'utils/html';
import Follow from 'Components/Follow';
import Image from 'Components/Image';
import Logo from 'Assets/img/logo.svg';
import s from './Footer.module.scss';

const Footer = ({items, follow}) => {
    const {t} = useTranslation();

    const classes = classNames(
        [s['Footer']],
        [s['Footer--HasCookieScript']],
    );
    return (
        <footer className={classes}>
            <div className={s['Footer__Wrap']}>
                <div className={s['Footer__Columns']}>
                    {items.map((item, index) => 
                        <Column 
                            {...item}
                            key={index}
                        />
                    )}

                    <div className={s['Footer__Follow']}>
                        <Follow {...follow} />
                    </div>

                    <Link href="/" className={s['Footer__Logo']}>
                        <Image
                            src={Logo.src}
                            alt={t('header.toStartpage')}
                            width={Logo.width}
                            height={Logo.height}
                        />
                    </Link>
                </div>
            </div>
        </footer>
    );
};

Footer.propTypes = {
    items: PropTypes.array,
    follow: PropTypes.object,
};

Footer.defaultProps = {
    items: [],
    follow: {},
};

const Column = ({title, text, children}) => (
    <div className={s['Footer__Column']}>
        <h2 className={s['Footer__Title']}>{title}</h2>
        <div className={s['Footer__Content']}>
            {text &&
                <div
                    className={s['Footer__Text']}
                    dangerouslySetInnerHTML={{
                        __html: sanitizeParagraphs(text, false),
                    }}
                />
            }
            <ul className={s['Footer__List']}>
                {children.map((item, index) =>
                    <Item 
                        {...item}
                        key={index}
                    />
                )}
            </ul>
        </div>
    </div>
);

Column.propTypes = {
    title: PropTypes.string,
    text: PropTypes.string,
    children: PropTypes.array,
};

Column.defaultProps = {
    title: '',
    text: '',
    children: [],
};

const Item = ({url, title}) => (
    <li className={s['Footer__Item']}>
        <a className={s['Footer__Link']} href={url}><span>{title}</span></a>
    </li>
);

Item.propTypes = {
    url: PropTypes.string,
    title: PropTypes.string,
};

Item.defaultProps = {
    url: '',
    title: '',
    text: '',
};

export default Footer;