import Animate, {
    AnimateInOut,
    AnimateUpDown,
    AnimateLeftRight,
} from './Animate';
export default Animate;

export { AnimateInOut, AnimateUpDown, AnimateLeftRight };

export const componentName = 'Animate';
